import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ArticleLink from "../components/article-link"
import { Link } from "gatsby"
import CardArticle from "../components/card-article"

const BlogPage = ({ pageContext, data }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog-index" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  const seo = {
    metaTitle: "Blog",
    metaDescription: "All blog posts",
  }
  return (
    <Layout seo={seo}>
      <div className="container mx-auto px-4 lg:px-12 mt-12">
        <div className="grid grid-cols-12 mb-12 sm:mb-18">
          <div class="col-span-12 md:col-span-8">
            <h1 className="text-4xl md:text-5xl lg:text-6xl mb-12 font-bold">
              Blog
            </h1>

            <div class="hidden md:block">
              {data.allStrapiArticle.edges.map((value, index) => {
                return <ArticleLink article={value} key={index} />
              })}
            </div>
            <div class="block md:hidden">
              {data.allStrapiArticle.edges.map((value, index) => {
                return <CardArticle article={value} key={index} />
              })}
            </div>
          </div>
        </div>

        <nav
          className="mb-12 relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              className={`relative inline-flex items-center px-4 py-2 border border-gray-300  text-md font-medium  ${
                currentPage === i + 1
                  ? "bg-green-500 text-white"
                  : "bg-white text-gray-700"
              }`}
              key={`pagination-number${i + 1}`}
              to={`/blog-index/${i === 0 ? "" : i + 1}`}
            >
              {i + 1}
            </Link>
          ))}
        </nav>

        {/* {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}

        {!isLast && (
          <Link to={nextPage} rel="next">
            Next Page →
          </Link>
        )} */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allStrapiArticle(
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt(formatString: "DD MMMM YYYY")
          category {
            name
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          author {
            name
            picture {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
