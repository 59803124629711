import React from "react"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"

export default function ArticleLink({ article }) {
  return (
    <article className="grid grid-cols-12 mb-12">
      <div className="col-span-8 sm:col-span-9">
        <Link to={`/blog/article/${article.node.slug}/`}>
          <h3 className="text-2xl font-bold">{article.node.title}</h3>
          <p className="text-lg text-gray-500 mb-8">
            {article.node.description}
          </p>
          {/* <p className="text-lg text-gray-500">{article.node.publishedAt}</p> */}
        </Link>
      </div>
      <div className="col-span-4 sm:col-span-3 ml-4 h-52">
        {" "}
        <Link to={`/blog/article/${article.node.slug}/`}>
          <BackgroundImage
            className="h-full"
            fluid={article.node.image.childImageSharp.fluid}
            alt=""
          />
        </Link>
      </div>
    </article>
  )
}
